<template>
  <v-simple-table>
    <template v-slot:default>
      <thead>
      <tr>
        <th class="text-no-wrap">ID Voucher</th>
        <th class="text-no-wrap">Montant</th>
        <th class="text-no-wrap">Utilisé ?</th>
        <th class="text-no-wrap">conv en magasin ?</th>
        <th class="text-no-wrap">Supplier</th>
        <th class="text-no-wrap">Téléphone</th>
        <th class="text-no-wrap">ِِAvec SMS ?</th>
        <th class="text-no-wrap">Par APi ?</th>
        <th class="text-no-wrap">Pour Refill ?</th>
        <th class="text-no-wrap">Nbr SMS</th>
        <th class="text-no-wrap">ID App</th>
        <th class="text-no-wrap">Actions</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td class="text-no-wrap">
          {{ item.voucher ? item.voucher.id : "-" }}
        </td>

        <td class="font-weight-medium gifty--text">
          {{ CurrencyFormatting(item.voucher ? (item.voucher.voucher ? item.voucher.voucher.amount : 0) : 0) }}
          DZD
        </td>

        <td class="text-no-wrap">
          {{
            item.voucher ? (item.voucher.voucher ? (item.voucher.voucher.used === 1 ? "Oui" : "Non") : "-") : "-"
          }}
        </td>

        <td class="text-no-wrap">
          {{
            item.voucher ? (item.voucher.voucher ? (item.voucher.store_used === 1 ? "Oui" : "Non") : "-") : "-"
          }}
        </td>
        <td class="text-no-wrap">
          {{
            item.voucher ? (item.voucher.voucher ? (item.voucher.voucher.supplier ? item.voucher.voucher.supplier.name : "-") : "-") : "-"
          }}
        </td>

        <td class="text-no-wrap text-center">
          {{ item.voucher ? item.voucher.phone : "-" }}
        </td>

        <td class="text-no-wrap text-center">
          {{
            item.voucher
                ? item.voucher.choice === "sms"
                    ? "oui"
                    : "non"
                : "-"
          }}
        </td>

        <td class="text-no-wrap">
          {{
            item.voucher
                ? (item.voucher.voucher ? (item.voucher.voucher.for_refill
                    ? "oui"
                    : "non") : "-")
                : "-"
          }}
        </td>

        <td class="text-no-wrap text-center">
          {{
            item.voucher
                ? (item.voucher.voucher ? (item.voucher.voucher.ecommerce_voucher_id
                    ? "oui"
                    : "non") : "-")
                : "-"
          }}
        </td>

        <td class="text-no-wrap text-center">
          {{ item.voucher ? item.voucher.sent_sms_number : "-" }}
        </td>

        <td class="text-no-wrap font-weight-medium gifty--text">
          {{ item.voucher ? item.voucher.app_user_id : "-" }}
        </td>

        <td class="text-no-wrap">
          <v-tooltip top color="gifty" v-if="item.voucher && item.voucher.choice === 'sms'">
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="success" icon v-bind="attrs" v-on="on" @click="resendSms()">
                <v-icon class="white--text"> mdi-message-processing</v-icon>
              </v-btn>
            </template>
            <span>Renvoyé sms</span>
          </v-tooltip>
        </td>

      </tr>
      </tbody>
    </template>
  </v-simple-table>

</template>

<script>

export default {
  props: ["item"],
  data() {
    return {};
  },
  methods: {
    resendSms() {
      this.$emit('resendSMS', this.item);
    }
  }
};
</script>
