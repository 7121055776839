<template>
  <v-dialog max-width="500" scrollable v-model="dialog" persistent>
    
    <v-card>
      <v-card-title>
        Balances (Debits)
        <v-spacer />
        <v-icon @click="dialog = false">mdi-close</v-icon>
      </v-card-title>
      <v-divider />
      <v-card-text class="pa-4">
        <v-row>
          <v-col cols="12">
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  

                  <tr>
                    <td class="text-no-wrap font-weight-bold">Balance</td>
                    <td
                      class="text-no-wrap font-weight-medium gifty--text text-center "
                      style="border-bottom: 1px solid #ddd !important"
                    >
                      {{ debits.debit_from_balance }} DZD
                    </td>
                  </tr>

                  <tr>
                    <td class="text-no-wrap font-weight-bold">Balance Credit</td>
                    <td
                      class="text-no-wrap font-weight-medium gifty--text text-center "
                      style="border-bottom: 1px solid #ddd !important"
                    >
                      {{ debits.debit_from_balance_credit }} DZD
                    </td>
                  </tr>

                  <tr>
                    <td class="text-no-wrap font-weight-bold">Balance Service</td>
                    <td
                      class="text-no-wrap font-weight-medium gifty--text text-center "
                      style="border-bottom: 1px solid #ddd !important"
                    >
                      {{ debits.debit_from_service_balance }} DZD
                    </td>
                  </tr>

                  <tr>
                    <td class=" font-weight-bold">Balance Credit Service</td>
                    <td
                      class="text-no-wrap font-weight-medium gifty--text text-center "
                      style="border-bottom: 1px solid #ddd !important"
                    >
                      {{ debits.debit_from_service_balance_credit }} DZD
                    </td>
                  </tr>

                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
var DefaultDebits = {
  debit_from_service_balance: 0,
  debit_from_service_balance_credit: 0,
  debit_from_balance: 0,
  debit_from_balance_credit: 0,
};

export default {
  data() {
    return {
      dialog: false,
      debits: DefaultDebits,
    };
  },

  methods: {
    open(debits) {
      this.debits = debits;
      this.dialog = true;
    },
    close() {
      this.debits = DefaultDebits;
      this.dialog = false;
    },
  },
};
</script>

<style scoped>
</style>