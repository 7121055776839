<template>
  <div>
    <v-container fluid>
      <div class="main">
        <v-skeleton-loader
          type="table"
          v-if="loading"
          class="mb-3"
        ></v-skeleton-loader>
        <v-row v-else>
          <v-col cols="12">
            <v-sheet :rounded="'lg'">
              <v-card-text>
                <div class="d-flex justify-space-between align-center">
                  <v-btn
                    text
                    color="grey"
                    class="rounded-lg"
                    @click="$router.push('/balance/history')"
                  >
                    <v-icon left>mdi-arrow-left</v-icon>
                    Retour
                  </v-btn>

                  <h4>
                    Histiorique balance:
                    <span class="pink--text darken-2">{{
                      account.user.name
                    }}</span>
                  </h4>
                </div>
              </v-card-text>
            </v-sheet>
          </v-col>

          <v-col cols="12">
            <v-chip
              color="gifty"
              :input-value="tab == 0"
              filter
              dark
              label
              @click="setTab(0)"
            >
              Transactions
              <v-icon small right>mdi-ticket-percent</v-icon>
            </v-chip>

            <v-chip
              class="ml-4"
              color="blue"
              :input-value="tab == 1"
              filter
              dark
              label
              @click="setTab(1)"
            >
              Commandes
              <v-icon small right>mdi-ticket-percent</v-icon>
            </v-chip>

            <div class="mt-4">
              <HistoryBalanceTable :histories="histories" v-if="tab == 0"/>
              <HistoryOrderBalanceTable :histories="ordershistories" v-if="tab == 1"/>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>
  
<script>
import HistoryBalanceTable from "./components/HistoryBalanceTable.vue";
import HistoryOrderBalanceTable from "./components/HistoryOrderBalanceTable.vue";

import { HTTP } from "@/http-common";
export default {
  components: { HistoryBalanceTable, HistoryOrderBalanceTable},
  data() {
    return {
      loading: true,

      histories: [],
      ordershistories: [],

      tab: 0,

      account: null,
    };
  },
  methods: {
    fetchData() {
      this.loading = true;
      HTTP.get("anomaly/account/balance/history/" + this.$route.params.id)
        .then((res) => {
          this.account = res.data.data;
          this.histories = this.account.balance_histories;
          this.ordershistories = this.account.balance_orders_histories;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },

    setTab(id) {
      this.tab = id;
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>

<style scoped>
tr.error td {
  background: rgb(248, 206, 223);
}
</style>