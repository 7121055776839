<template>
  <div>
    <v-sheet :rounded="'lg'">
      <v-card-text>
        <div v-if="histories.length > 0">
          <v-data-table
            :items="histories"
            :single-expand="true"
            :expanded.sync="expanded"
            :hide-default-header="true"
            :hide-default-footer="false"
            item-key="id"
            show-expand
            :mobile-breakpoint="0"
            :headers="headers"
          >
            <template v-slot:header="{}">
              <thead style="widows: 100%">
                <tr style="widows: 100%">
                  <th class="text-no-wrap">ID</th>
                  <th class="text-no-wrap">Old value</th>
                  <th class="text-no-wrap">New Value</th>
                  <th class="text-no-wrap">Comment</th>
                  <th class="text-no-wrap">Nom</th>
                  <th class="text-no-wrap">Date</th>
                  <th class="text-no-wrap">Montant</th>
                  <th class="text-no-wrap">Frais Service</th>
                  <th class="text-no-wrap">Promotion</th>
                  <th class="text-no-wrap">Code Promotion</th>
                  <th class="text-no-wrap">Total</th>
                  <th class="text-no-wrap">Debits</th>
                  <th class="text-no-wrap">Logique ?</th>

                  <th class="text-no-wrap">Service</th>
                  <th class="text-no-wrap">Mode Paiement</th>
                </tr>
              </thead>
            </template>
            <template v-slot:item="{ item }">
              <tr
                style="widows: 100%"
                :class="getValidity(item) ? '' : 'error'"
              >
                <td class="text-no-wrap">{{ item.id }}</td>

                <td class="text-no-wrap font-weight-medium gifty--text">
                  {{ CurrencyFormatting(item.old_balance) }} DZD
                </td>
                <td class="text-no-wrap font-weight-medium gifty--text">
                  {{ CurrencyFormatting(item.new_balance) }} DZD
                </td>
                <td class="text-no-wrap">{{ item.comment }}</td>
                <td
                  class="text-no-wrap font-weight-medium"
                  style="cursor: pointer"
                >
                  <v-avatar size="35">
                    <v-img :src="require('@/assets/avatar.png')" />
                  </v-avatar>
                  {{
                    item.request_user ? item.request_user.name : "pas de user"
                  }}
                </td>

                <td class="text-no-wrap">
                  {{ item.created_at }}
                </td>

                <td class="text-no-wrap font-weight-medium gifty--text">
                  {{
                    CurrencyFormatting(
                      item.order_voucher ? item.order_voucher.amount : 0
                    )
                  }}
                  DZD
                </td>

                <td
                  class="text-no-wrap font-weight-medium gifty--text text-center"
                >
                  <span
                    v-if="
                      item.order_voucher &&
                      item.order_voucher.total_voucher_order_fees
                    "
                  >
                    {{
                      CurrencyFormatting(
                        item.order_voucher.total_voucher_order_fees
                      )
                    }}
                    DZD
                  </span>
                  <span v-else> / </span>
                </td>

                <td
                  class="text-no-wrap font-weight-medium gifty--text text-center"
                >
                  <span
                    v-if="
                      item.order_voucher &&
                      item.order_voucher.voucher_order_promotion
                    "
                  >
                    {{
                      CurrencyFormatting(
                        item.order_voucher.voucher_order_promotion
                          .promotion_amount
                      )
                    }}
                    DZD
                  </span>
                  <span v-else> / </span>
                </td>

                <td
                  class="text-no-wrap font-weight-medium gifty--text text-center"
                >
                  <span
                    v-if="
                      item.order_voucher &&
                      item.order_voucher.voucher_order_promo_code
                    "
                  >
                    {{
                      CurrencyFormatting(
                        item.order_voucher.voucher_order_promo_code
                          .promo_code_amount
                      )
                    }}
                    DZD
                  </span>
                  <span v-else> / </span>
                </td>

                <td
                  class="text-no-wrap font-weight-medium gifty--text text-center success lighten-5"
                  style="border-bottom: 1px solid #ddd !important"
                >
                  {{ CurrencyFormatting(item.order_voucher.total_amount) }} DZD
                </td>

                <td>
                  <v-btn
                    icon
                    class="gifty"
                    small
                    @click="openDebits(item.order_voucher)"
                    v-if="item.order_voucher.debits"
                  >
                    <v-icon class="white--text" small>
                      mdi-page-next-outline
                    </v-icon>
                  </v-btn>
                </td>

                <td class="text-center">
                  <v-icon v-if="getValidity(item)" class="success--text"
                    >mdi-check</v-icon
                  >
                  <v-icon v-else class="red--text">mdi-close</v-icon>
                </td>

                <td class="text-no-wrap" v-if="item.order_voucher">
                  Service
                  {{
                    item.order_voucher.service != null
                      ? item.order_voucher.service.name
                      : "-"
                  }}
                </td>
                <td v-else></td>

                <td class="text-center">
                  <img
                    src="@/assets/carte_CIB.jpg"
                    width="40px"
                    v-if="item.order_voucher.with_e_payment"
                  />
                  <img src="@/assets/logo_gifty.svg" width="40px" v-else />
                </td>
              </tr>
            </template>
          </v-data-table>
        </div>
        <div v-else>
          <div class="text-center pb-5">
            <v-avatar tile size="200">
              <v-img :src="require('@/assets/database.svg')"></v-img>
            </v-avatar>
            <h3 class="text--primary d-block mb-2">OOPS !</h3>
            <p>Aucun enregistrement correspondant trouvé.</p>
          </div>
        </div>
      </v-card-text>
    </v-sheet>
    <Debits ref="debitsModal" />
  </div>
</template>
  
  <script>
import Debits from "@/views/support/components/Debits.vue";

export default {
  props: ["histories"],
  components: { Debits },
  data() {
    return {
      // Headers
      expanded: [],
      singleExpand: true,

      filter: {
        size: 10,
        page: 1,
        current: 1,
        total: 0,
      },

      headers: [
        { text: "ID", value: "id" },
        { text: "Old Value", value: "id" },
        { text: "New Value", value: "id" },
        { text: "Comment", value: "id" },
        { text: "Nom", value: "account.user.name" },
        { text: "Date", value: "created_at" },
        { text: "Montant", value: "amount" },
        { text: "Frais", value: "amount" },
        { text: "Promotion", value: "amount" },
        { text: "Promotion Code", value: "amount" },
        { text: "Total", value: "amount" },
        { text: "Total", value: "amount" },
        { text: "Debits", value: "amount" },
        { text: "Logique", value: "amount" },
        { text: "Type", value: "type" },
        { text: "Service", value: "service.name" },
        { text: "Mode paiement", value: "service.name" },
        { text: "Actions", value: "" },
      ],
    };
  },
  methods: {
    getValidity(item) {
      var diff = Math.abs(item.old_balance - item.new_balance);

      // montant sortant
      if (item.order_voucher) {
        var fee = 0;
        var promotion = 0;
        var promotionCode = 0;

        if (item.order_voucher.total_voucher_order_fees > 0) {
          fee = item.order_voucher.total_voucher_order_fees;
        }

        if (item.order_voucher.voucher_order_promotion) {
          promotion =
            item.order_voucher.voucher_order_promotion.promotion_amount;
        }
        if (item.order_voucher.voucher_order_promo_code) {
          promotionCode =
            item.order_voucher.voucher_order_promo_code.promo_code_amount;
        }
        var totalPromo = promotion + promotionCode;

        //   console.log("totalPromo : "+totalPromo);
        //   console.log("order_voucher Amount : "+item.order_voucher.amount);
        //   console.log("promotionCode : "+promotionCode);
        //   console.log("Fee  : "+fee);

        if (item.order_voucher.amount - totalPromo + fee == diff) {
          return true;
        }
      } else {
        return false;
      }
    },
    openDebits(item) {
      if (item.debits) {
        this.$refs.debitsModal.open(item.debits);
      }
    },
  },
};
</script>
  
  <style scoped>
</style>