<template>
  <div>
    <v-sheet :rounded="'lg'">
      <v-card-text>
        <div v-if="histories.length > 0">
          <v-data-table :items="histories" 
          :single-expand="true" :expanded.sync="expanded" 
          :hide-default-header="true"
            :hide-default-footer="false" 
            item-key="id" show-expand 
            :mobile-breakpoint="0" 
            :headers="headers"
            :footer-props="{'items-per-page-options':[15, 30, 50, 100, 200, -1]}" 
            >
            <template v-slot:header="{}">
              <thead style="widows: 100%">
                <tr style="widows: 100%">
                  <th class="text-no-wrap">ID</th>
                  <th class="text-no-wrap">Old value</th>
                  <th class="text-no-wrap">New Value</th>
                  <th class="text-no-wrap">Comment</th>
                  <th class="text-no-wrap">Nom</th>
                  <th class="text-no-wrap">Date</th>
                  <th class="text-no-wrap">Montant</th>
                  <th class="text-no-wrap">Recepteur</th>
                  <th class="text-no-wrap">Frais Service</th>
                  <th class="text-no-wrap">Promotion</th>
                  <th class="text-no-wrap">Code Promotion</th>
                  <th class="text-no-wrap">Commission Payee</th>
                  <th class="text-no-wrap">Total</th>
                  <th class="text-no-wrap">Debits</th>
                  <th class="text-no-wrap">Logique ?</th>
                  <th class="text-no-wrap">Type</th>
                  <th class="text-no-wrap">Service</th>
                  <th class="text-no-wrap">Mode paiement</th>
                  <th class="text-no-wrap">Action</th>
                </tr>
              </thead>
            </template>
            <template v-slot:item="{ item, expand, isExpanded }">
              <tr style="widows: 100%" :class="getValidity(item) ? '' : 'error'">
                <td class="text-no-wrap">{{ item.id }}</td>
                <td class="text-no-wrap font-weight-medium gifty--text">
                  {{ CurrencyFormatting(item.old_balance) }} DZD
                </td>
                <td class="text-no-wrap font-weight-medium gifty--text">
                  {{ CurrencyFormatting(item.new_balance) }} DZD
                </td>
                <td class="text-no-wrap">{{ item.comment }}</td>
                <td class="text-no-wrap font-weight-medium" style="cursor: pointer">
                  <v-avatar size="35">
                    <v-img :src="require('@/assets/avatar.png')" />
                  </v-avatar>
                  {{
                    item.request_user ? item.request_user.name : "pas de user"
                  }}
                </td>
                <td class="text-no-wrap">
                  {{ item.created_at }}
                </td>
                <td class="text-no-wrap font-weight-medium gifty--text">
                  {{
                    CurrencyFormatting(
                      item.transaction ? item.transaction.amount : 0
                    )
                  }}
                  DZD
                </td>

                <td class="text-no-wrap font-weight-medium pointer"
                                                @click="showAccount(item.transaction.recipient_account_id)">
                                                <v-avatar size="35" v-if="item.transaction.recipient_account">
                                                    <v-img :src="require('@/assets/avatar.png')" />
                                                </v-avatar>
                                                {{ item.transaction.recipient_account ? item.transaction.recipient_account.user ? item.transaction.recipient_account.user.name : "-"
                                                    : "-" }}
                                            </td>

                <td class="text-no-wrap font-weight-medium gifty--text text-center">
                  <span v-if="item.transaction &&
                      item.transaction.total_transaction_fees > 0
                      ">
                    {{
                      CurrencyFormatting(
                        item.transaction.total_transaction_fees
                      )
                    }}
                    DZD
                  </span>
                  <span v-else> / </span>
                </td>

                <td class="text-no-wrap font-weight-medium gifty--text text-center">
                  <span v-if="item.transaction && item.transaction.transaction_promotion
                      ">
                    {{
                      CurrencyFormatting(
                        item.transaction.transaction_promotion.promotion_amount
                      )
                    }}
                    DZD
                  </span>
                  <span v-else> / </span>
                </td>

                <td class="text-no-wrap font-weight-medium gifty--text text-center">
                  <span v-if="item.transaction &&
                      item.transaction.transaction_promo_code
                      ">
                    {{
                      CurrencyFormatting(
                        item.transaction.transaction_promo_code
                          .promo_code_amount
                      )
                    }}
                    DZD
                  </span>
                  <span v-else> / </span>
                </td>

                <td class="text-no-wrap font-weight-medium gifty--text text-center">
                  {{
                    CurrencyFormatting(
                      item.transaction ? item.transaction.paied_commissions : 0
                    )
                  }}
                  DZD
                </td>

                <td class="text-no-wrap font-weight-medium gifty--text text-center success lighten-5"
                  style="border-bottom: 1px solid #ddd !important">
                  {{
                    CurrencyFormatting(
                      item.transaction ? item.transaction.total_amount : 0
                    )
                  }}
                  DZD
                </td>

                <td>
                  <v-btn icon class="gifty" small @click="openDebits(item.transaction)"
                    v-if="item.transaction && item.transaction.debits">
                    <v-icon class="white--text" small>
                      mdi-page-next-outline
                    </v-icon>
                  </v-btn>
                </td>

                <td class="text-center">
                  <v-icon v-if="getValidity(item)" class="success--text">mdi-check</v-icon>
                  <v-icon v-else class="red--text">mdi-close</v-icon>
                </td>

                <td class="text-no-wrap" v-if="item.transaction">
                  <v-btn text color="grey" v-if="item.transaction.type === 'sent_refill'" class="text-none">
                    <v-icon left color="indigo">
                      mdi-arrow-up-right-bold
                    </v-icon>
                    Sode envoyé
                  </v-btn>

                  <v-btn text color="grey" v-if="item.transaction.type === 'received_refill'" class="text-none">
                    <v-icon left color="success">
                      mdi-arrow-down-left-bold
                    </v-icon>
                    Sode reçu
                  </v-btn>

                  <v-btn text color="grey" v-if="item.transaction.type === 'voucher'" class="text-none">
                    <v-icon left color="primary"> mdi-ticket-percent </v-icon>
                    Voucher
                  </v-btn>

                  <v-btn text color="grey" v-if="item.transaction.type === 'flexy'" class="text-none">
                    <v-icon left color="gifty"> mdi-cellphone-wireless </v-icon>
                    Flexy
                  </v-btn>

                  <v-btn text color="grey" v-if="item.transaction.type === 'Idoom' ||
                    item.transaction.type === '4G'
                    " class="text-none">
                    <v-icon left color="info"> mdi-card-multiple </v-icon>
                    Idoom
                  </v-btn>
                </td>
                <td v-else></td>

                <td class="text-no-wrap" v-if="item.transaction">
                  Service
                  {{
                    item.transaction.service != null
                    ? item.transaction.service.name
                    : "-"
                  }}
                </td>
                <td v-else></td>
                <td class="text-center">
                  <img src="@/assets/carte_CIB.jpg" width="40px"
                    v-if="item.transaction && item.transaction.with_e_payment" />
                  <img src="@/assets/logo_gifty.svg" width="40px" v-else />
                </td>

                <td class="text-no-wrap d-flex align-center">
                  <v-btn @click.prevent="[expand(!isExpanded)]" icon>
                    <v-icon v-if="!isExpanded">mdi-plus-circle-outline</v-icon>
                    <v-icon v-if="isExpanded">mdi-minus-circle-outline</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>

            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length" v-if="item.transaction != null">
                <DetailsVoucher class="pa-4 grey lighten-5" :item="item.transaction"
                  v-if="item.transaction.type === 'voucher'" />

                <DetailsCard :item="item.transaction" class="pa-4 grey lighten-5" v-if="item.transaction.type === 'Idoom' ||
                  item.transaction.type === '4G'
                  " />

                <DetailsRefill class="pa-4 grey lighten-5" :item="item.transaction" v-if="item.transaction.type === 'sent_refill' ||
                  item.transaction.type === 'received_refill'
                  " />
                <DetailsFlexy class="pa-4 grey lighten-5" :item="item.transaction"
                  v-if="item.transaction.type === 'flexy'" />
              </td>
              <td v-else></td>
            </template>
          </v-data-table>
        </div>
        <div v-else>
          <div class="text-center pb-5">
            <v-avatar tile size="200">
              <v-img :src="require('@/assets/database.svg')"></v-img>
            </v-avatar>
            <h3 class="text--primary d-block mb-2">OOPS !</h3>
            <p>Aucun enregistrement correspondant trouvé.</p>
          </div>
        </div>
      </v-card-text>
    </v-sheet>

    <Debits ref="debitsModal" />
  </div>
</template>

<script>
import DetailsVoucher from "../../support/components/DetailsVoucher.vue";
import DetailsCard from "../../support/components/DetailsCard.vue";
import DetailsRefill from "../../support/components/DetailsRefill.vue";
import DetailsFlexy from "../../support/components/DetailsFlexy.vue";
import Debits from "@/views/support/components/Debits.vue";
export default {
  props: ["histories"],
  components: {
    DetailsRefill,
    DetailsVoucher,
    DetailsCard,
    DetailsFlexy,
    Debits,
  },
  data() {
    return {
      // Headers
      expanded: [],
      singleExpand: true,

      filter: {
        size: 10,
        page: 1,
        current: 1,
        total: 0,
      },

      headers: [
        { text: "ID", value: "id" },
        { text: "Old Value", value: "id" },
        { text: "New Value", value: "id" },
        { text: "Comment", value: "id" },
        { text: "Nom", value: "account.user.name" },
        { text: "Date", value: "created_at" },
        { text: "Montant", value: "amount" },
        { text: "Frais", value: "amount" },
        { text: "Promotion", value: "amount" },
        { text: "Promotion Code", value: "amount" },
        { text: "Total", value: "amount" },
        { text: "Total", value: "amount" },
        { text: "Debits", value: "amount" },
        { text: "Logique", value: "amount" },
        { text: "Type", value: "type" },
        { text: "Service", value: "service.name" },
        { text: "Mode paiement", value: "service.name" },
        { text: "Actions", value: "" },
      ],
    };
  },
  methods: {
    getValidity(item) {
      var diff = item.old_balance - item.new_balance;
      if(["Commission Operation", "Voucher commission Operation"].includes(item.comment)){
        if(item.transaction  ){
          return item.transaction.paied_commissions == -1*diff;
        }
        return false;
        
      }
      

      if (diff > 0) {

        // montant sortant
        if (item.transaction) {
          var fee = 0;
          var promotion = 0;
          var promotionCode = 0;
          var paied_commission = 0;



          if (item.transaction.total_transaction_fees > 0) {
            fee = item.transaction.total_transaction_fees;
          }

          if (item.transaction.transaction_promotion) {
            promotion = item.transaction.transaction_promotion.promotion_amount;
          }
          if (item.transaction.transaction_promo_code) {
            promotionCode =
              item.transaction.transaction_promo_code.promo_code_amount;
          }

          if (item.transaction) {
            paied_commission = item.transaction.paied_commissions;
          }
          if (
            item.transaction.amount - promotion - promotionCode + fee ==
            diff //&& item.transaction.account_id == item.account_id
          ) {
            return true;
          }else{
            console.log("============ Error ===========")
          console.log(item);
          }
        } else {
          
          return false;
        }
      } else {
        diff = -1 * diff;
        // commission
        

        // montant entrant
        
        if (
          item.transaction) {
          if (item.transaction.amount == diff) {
            return true;
          }
        }
        return false;
      }
      return false;
    },
    openDebits(item) {
      if (item.debits) {
        this.$refs.debitsModal.open(item.debits);
      }
    },
  },
};
</script>

<style scoped></style>