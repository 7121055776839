<template>
  <v-simple-table>
    <template v-slot:default>
      <thead style="widows: 100%;">
        <tr>
          <th>ID Carte</th>
          <th>Montant</th>
          <th>Utilisé ?</th>
          <th>Type</th>
          <th>Téléphone</th>
          <th>État</th>
          <th>ِِAvec SMS ?</th>
          <th>Nbr SMS</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody style="widows: 100%;">
        <tr>
          <td class="text-no-wrap">
            {{ item.idoom_card ? (item.idoom_card.card ? item.idoom_card.card.id : "-") : '-' }}
          </td>
          <td class="gifty--text font-weight-medium text-no-wrap">
            {{
              CurrencyFormatting(
                item.idoom_card
                  ? item.idoom_card.card
                    ? item.idoom_card.card.amount
                    : 0
                  : 0
              )
            }}
            DZD
          </td>

          <td class="text-no-wrap">
            {{
              item.idoom_card
                ? item.idoom_card.card
                  ? item.idoom_card.card.used
                    ? "Oui"
                    : "Non"
                  : "-"
                : "-"
            }}
          </td>

          <td class="text-no-wrap">
            {{
              item.idoom_card
                ? item.idoom_card.card
                  ? item.idoom_card.card.type
                  : "-"
                : "-"
            }}
          </td>

          <td class="text-no-wrap">
            {{ item.idoom_card ? item.idoom_card.phone : "-" }}
          </td>
          
          <td class="text-no-wrap">
            <div v-if="item.idoom_card && item.idoom_card.card && item.idoom_card.card.martship_card">
              <v-chip  small
                  class="ma-2"
                  :color="$martship_states.colors[item.idoom_card.card.martship_card.martship_state]"
                  text-color="white"
                >
                  {{$martship_states.texts[item.idoom_card.card.martship_card.martship_state]}}
                </v-chip>
                ({{item.idoom_card.card.martship_card.mode}})
                <v-btn icon @click="$emit('openMartchipCard', item.idoom_card.card.martship_card )">
                  <v-icon>mdi-card-bulleted-outline</v-icon>
                </v-btn>
              </div>
              <v-chip v-else small
                class="ma-2"
                color="gifty"
                text-color="white"
              >
                Par stock
              </v-chip>

          </td>


          <td class="text-no-wrap">
            {{
              item.idoom_card
                ? item.idoom_card.choice == "sms"
                  ? "oui"
                  : "Non"
                : "-"
            }}
          </td >

          <td class="text-no-wrap">{{ item.idoom_card ? item.idoom_card.sent_sms_number : "" }}</td>

          <td class="text-no-wrap">
            <v-tooltip
              top
              color="gifty"
              v-if="item.idoom_card && item.idoom_card.choice == 'sms'"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="success"
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="resendSms()"
                >
                  <v-icon class="white--text"> mdi-message-processing </v-icon>
                </v-btn>
              </template>
              <span>Renvoyé sms</span>
            </v-tooltip>
          </td>

        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>
  
  <script>
export default {
  props: ["item"],
  data() {
    return {};
  },
  methods: {
    resendSms(){
        this.$emit('resendSMS', this.item);
    }
  }
};
</script>

