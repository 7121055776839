<template>
  <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-no-wrap">ID Refill</th>
          <th class="text-no-wrap">Montant</th>
          <th class="text-no-wrap">Emetteur</th>
          <th class="text-no-wrap">Recepteur</th>
          <th class="text-no-wrap">Service Unique</th>
          <th class="text-no-wrap">Etat</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="text-no-wrap">
            {{ item.refill ? item.refill.id : "-" }}
          </td>

          <td class="gifty--text font-weight-medium text-no-wrap">
            {{ CurrencyFormatting(item.refill ? item.refill.amount : 0) }}
            DZD
          </td>

          <td
            class="text-no-wrap font-weight-medium"
            @click="showAccount(item.refill.sender_account_id)"
            style="cursor: pointer"
          >
            <v-avatar size="35" v-if="item.refill.sender">
              <v-img
                :src="item.refill.sender.image"
                v-if="item.refill.sender.image"
              />
              <v-img :src="require('@/assets/avatar.png')" v-else />
            </v-avatar>
            {{
              item.refill
                ? item.refill.sender
                  ? item.refill.sender.name
                  : "-"
                : "-"
            }}
          </td>

          <td
            class="text-no-wrap font-weight-medium"
            @click="showAccount(item.refill.recipient_account_id)"
            style="cursor: pointer"
          >
            <v-avatar size="35" v-if="item.refill.recipient">
              <v-img
                :src="item.refill.recipient.image"
                v-if="item.refill.recipient.image"
              />
              <v-img :src="require('@/assets/avatar.png')" v-else />
            </v-avatar>
            {{
              item.refill
                ? item.refill.recipient
                  ? item.refill.recipient.name
                  : "-"
                : "-"
            }}
          </td>

          <td class="text-no-wrap font-weight-medium">
            <v-avatar size="30" v-if="item.refill && item.refill.service">
              <v-img :src="getImageLink(item.refill.service.image)" />
            </v-avatar>
            {{
              item.refill
                ? item.refill.service
                  ? item.refill.service.name
                  : "-"
                : "-"
            }}
          </td>

          <td class="text-no-wrap">
            <v-chip
              class="ma-2"
              :color="colors[item.refill.state]"
              text-color="white"
            >
              {{ texts[item.refill.state] }}
            </v-chip>
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>
  
  <script>
export default {
  props: ["item"],
  data() {
    return {
      texts: {
        pending: "Pending",
        accepted: "Acceptée",
        refused: "Refusée",
        vrification: "Vérification",
      },
      colors: {
        pending: "primary",
        accepted: "success",
        refused: "red",
        vrification: "blue",
      },
    };
  },

  methods: {
    showAccount(account_id) {
      this.$router.push("/users/" + account_id);
    },
  },
};
</script>
