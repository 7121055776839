<template>
  <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr style="widows: 100%">
          <th class="text-no-wrap">ID Flexy</th>
          <th class="text-no-wrap">Montant</th>
          <th class="text-no-wrap">Telephone</th>
          <th class="text-no-wrap">Type</th>
          <th class="text-no-wrap">État</th>
        </tr>
      </thead>
      <tbody style="widows: 100%">
        <tr>
          <td class="text-no-wrap">
            {{ item.flexy ? item.flexy.id : "-" }}
          </td>
          <td class="font-weight-medium gifty--text text-no-wrap">
            {{ CurrencyFormatting(item.flexy ? item.flexy.amount : 0) }} DZD
          </td>
          <td class="text-no-wrap">
            {{ item.flexy ? item.flexy.phone : "-" }}
          </td>
          <td class="text-no-wrap">
            {{ item.flexy ? item.flexy.type : "-" }}
          </td>
          <td class="text-no-wrap">
            <v-chip v-if="item.flexy" small
                class="ma-2"
                :color="$martship_states.colors[item.flexy.martship_state]"
                text-color="white"
              >
                {{$martship_states.texts[item.flexy.martship_state]}}
              </v-chip>
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  props: ["item"],
  data() {
    return {
    };
  },
};
</script>

